import { topMain } from './index';
const loadImg = () => {
  // let img = document.getElementsByClassName("loadImg");
  // let imgLength = img.length;
  // let count = 0;

  // return new Promise(resolve => {
  //   for(let i=0; i<imgLength; i++) {
  //     img[i].addEventListener('load', event=> {
  //       // count = count + (i + 1);
  //       // if(count == 1/2*imgLength*(imgLength+1)){
  //       if(i == imgLength-1){
  //         resolve();
  //         // console.log("loadImgEnd");
  //       }
  //     });
  //     img[i].src = img[i].getAttribute("data-src");
  //   }
  // });
    
    let img = document.getElementsByClassName("loadImg");
  
    for (let i = 0; i < img.length; i++) {
  
      img[i].addEventListener('load', () => {
        // console.log(e.target.src);
        if (i == img.length - 1) {
          console.log('end');
          topMain();
        }
      });
  
      img[i].src = img[i].getAttribute('data-src');
    }

};




export default loadImg;