const accessTab = (() => {
  const bodyAccess = (document.getElementById("bodyAccess") != null);
  const bodyLocation = (document.getElementById("bodyLocation") != null);
  if (bodyAccess || bodyLocation ) {

    const tabBtn = document.querySelectorAll('.tabBtn');
    const tabCntnt = document.querySelectorAll('.tabCntnt');

    tabBtn[0].classList.add('tabAct');
    tabCntnt[0].classList.add('tabAct');

    let count = 0;

    for (let i = 0; i < tabBtn.length; i++) {
      tabBtn[i].addEventListener('click', (event) => {

        for (let j = 0; j < tabBtn.length; j++) {
          tabBtn[j].classList.remove('tabAct');
          tabCntnt[j].classList.remove('tabAct');
        }

        tabBtn[i].classList.add('tabAct');
        tabCntnt[i].classList.add('tabAct');

      });
    }

  }
})();
export default accessTab;