import { scrollControlFunc, windowResize, loadImg } from './index';

const loadFunc = (() => {
  let sW = document.documentElement.clientWidth;
  let sH = window.innerHeight;
  let body = document.querySelector('body');
  let loadWrap = document.getElementById('loadWrap');
  scrollControlFunc.noScroll();
  windowResize();
  
  const topMainLoad = () => {
    console.log("loadFun");
    loadImg();
    loadWrap.classList.add('no_act');
  }

  
  const startFunc = () => {
    scrollControlFunc.returnScroll();
    const bodyTop = (document.getElementById("bodyTop") != null);
    const comSecMain = (document.getElementById("comSecMain") != null);
    // const mainBox = (document.getElementById("mainBox") != null);
    
    if (bodyTop) {
      topMainLoad()
    }else if (comSecMain) {
      loadWrap.classList.add('no_act');
    }else{
      loadWrap.classList.add('no_act');
    }
  }

  setTimeout(startFunc, 300)

})();

export default loadFunc;