const modelroom = (() => {
  const bodyModel = (document.getElementById("bodyModelroom") != null);
  if (bodyModel) {

    const thumb = document.querySelectorAll('.thumb');

    const switchThumb = (index) => {
      document.querySelector('.thumb-active').classList.remove('thumb-active');
      thumb[index].classList.add('thumb-active');
    }

    const mySwiper = new Swiper('.swiper-container', {
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      // effect: 'fade',
      speed: 1000,
      on: {
        afterInit: (swiper) => {
          thumb[swiper.realIndex].classList.add('thumb-active');
          for (let i = 0; i < thumb.length; i++) {
            thumb[i].onclick = () => {
              swiper.slideTo(i+1);
            };
          }
        },
        slideChange: (swiper) => {
          switchThumb(swiper.realIndex);
        },
      }
    });


  }
})();
export default modelroom;