const typeImg = (() => {
  const bodyType = (document.getElementById("bodyType") != null);
  if (bodyType) {

    window.addEventListener('load', function(){
      // var img_elements = document.querySelectorAll("img");    
      // console.log(img_elements);
      let TypeImg = document.querySelector(".type_img");
      let TypeSrc = document.querySelector(".type_img_src");
      // console.log(Type);
      // console.log(TypeSrc.naturalWidth);
      TypeImg.style.setProperty('--base-width', TypeSrc.naturalWidth + 'px');
      TypeImg.style.setProperty('opacity','1');
    });
    

  }
})();
export default typeImg;