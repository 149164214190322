const comFadeIn = (() => {

  let sW = document.documentElement.clientWidth;

  let setPosition;
  let setDuration;
  if(sW > 750){
    setPosition = 100
    setDuration = 1
  }else{
    setPosition = 50
    setDuration = .75
  }

  gsap.set(".comFadeIn", { opacity: 0, y: setPosition }); //初期状態をセット

  ScrollTrigger.batch(".comFadeIn", {
    onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, duration: setDuration,}),
    start: "0% 80%",
    once: true //この指定によって１度だけアニメーションされる
  });

})()
export default comFadeIn;