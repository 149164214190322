const planTab = (() => {
  const bodyPlan = (document.getElementById("bodyPlan") != null);
  if (bodyPlan) {

    // const tabBtn = document.querySelectorAll('.tabBtn');
    // const tabCntnt = document.querySelectorAll('.tabCntnt');
    const Numbtn = document.querySelectorAll('.slideBtn');

    // tabBtn[0].classList.add('tabAct');
    // tabCntnt[0].classList.add('tabAct');

    // let count = 0;

    // for (let i = 0; i < tabBtn.length; i++) {
    //   tabBtn[i].addEventListener('click', (event) => {
        
    //     if(tabBtn[i].classList.contains('tabAct')){
    //       tabBtn[i].classList.remove('tabAct');
    //       tabCntnt[i].classList.remove('tabAct');
    //     }else{
    //       for (let j = 0; j < tabBtn.length; j++) {
    //         tabBtn[j].classList.remove('tabAct');
    //         tabCntnt[j].classList.remove('tabAct');
    //       }
    //       tabBtn[i].classList.add('tabAct');
    //       tabCntnt[i].classList.add('tabAct');
    //       slick.slick('setPosition');
    //     }

    //   });
    // }

    
    var slick = $('.slide_wrap').slick({
      infinite: true,
      touchMove: false,
      draggable: false,
      prevArrow: '<div class="slick-arrow_prev"></div>',
      nextArrow: '<div class="slick-arrow_next"></div>',
    });

    // for (let i = 0; i < Numbtn.length; i++) {
    //   Numbtn[i].addEventListener('click', (event) => {
    //     // console.log(Numbtn[i]);
    //     slick.slick('slickGoTo', i, false);
    //     console.log(i);
    //   });
    // }
    $('.button1').on('click', function() {
      console.log("a");
      slick.slick('slickGoTo', 0, false);
    });
    $('.button2').on('click', function() {
      slick.slick('slickGoTo', 1, false);
    });
    $('.button3').on('click', function() {
      slick.slick('slickGoTo', 2, false);
    });
    $('.button4').on('click', function() {
      slick.slick('slickGoTo', 3, false);
    });
    $('.button5').on('click', function() {
      slick.slick('slickGoTo', 4, false);
    });
    $('.button6').on('click', function() {
      slick.slick('slickGoTo', 5, false);
    });


  }
})();
export default planTab;